@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


$color-primary: #5937b9;
$color-success: #32be56;
.text-primary {
  color: $color-primary;
}
.text-success {
  color: $color-success;
}

.hover-on-display:hover{
    > span {
        display: block;
    }
}

.public-review {
  .title{
    font-family: 'Playfair Display';
  }
  
  .sub-heading {
    color: #333333;
  }
  .muted-description {
    color: #757575;
  }
  .outline-button {
    color: $color-primary;
    border: solid 1px;
    background: white;
    border-color: $color-primary;
  }
}